<template>
    <div ref="hoverElement" style="background-color: cornsilk!important;max-width: 600px;text-wrap: pretty;" 
    class="popover-personinfo bs-popover-auto fade shadow" role="tooltip">
        <div v-if="isLoaded" class="popover-header p-2" style="background-color: cornsilk!important;">
            <div class="fw-bold d-flex ">
                <div>
                    <small>{{itemInfo.Title}} (ID: {{itemInfo.ID}})</small>
                </div>
                <div class="ms-auto me-1" style="cursor: default" :style="itemInfo.MobileDetailApp_ID ? 'width: 37px;' : ' '">
                    <a v-if="itemInfo.MobileDetailApp_ID" :href="`/nt/${itemInfo.MobileDetailApp_ID}?ID=${itemInfo.ID}`" target="_blank" rel="noreferrer noopener" :title="$t('Open in mobile app')"><i class="bi bi-phone"></i></a>
                    <a :href="`/${itemInfo.DetailApp_ID}?ID=${itemInfo.ID}`"><i class="bi bi-box-arrow-up-right ms-1"></i></a>
                </div>
            </div>
        </div>
        <h3 v-else class="popover-header p-2">
            {{$t('Loading...')}}
        </h3>
        <div class="popover-body p-2">
            <template v-if="isLoaded">
                <div class="row gx-5" style="font-size:small">
                    <div class="col-12 mb-0">
                        <span style="font-weight: 500;">{{ $t("Process") }}:</span>&nbsp;<span>{{ itemInfo.Process }}</span>
                    </div>
                    <div class="col-12 mb-0">
                        <span style="font-weight: 500;">{{ $t("OrgUnit") }}:</span>&nbsp;<span>{{ itemInfo.OrgUnit }}</span>
                    </div>
                    <div class="col-12 mb-0" v-if="itemInfo.CompletionPhase">
                        <span style="font-weight: 500;">{{ $t("Completion Phase") }}:</span>&nbsp;<span>{{ itemInfo.CompletionPhase }}</span>
                    </div>
                    <div class="col-12 mb-0" v-if="itemInfo.HandoverType">
                        <span style="font-weight: 500;">{{ $t("Handover Type") }}:</span>&nbsp;<span>{{ itemInfo.HandoverType }}</span>
                    </div>
                    <div class="col-12 mb-0" v-if="itemInfo.DueDate">
                        <span style="font-weight: 500;">{{ $t("Due Date") }}:</span>&nbsp;<span>{{ $formatDate(itemInfo.DueDate, 'Short Date') }}</span>
                    </div>
                    <div class="col-12 mb-2"></div>
                    <div class="mt-1" style="max-height: 100px; overflow-y: hidden; word-break: break-all;">
                        {{ itemInfo.Description }}
                    </div>
                    <div class="col-12 mb-0 mt-1 fst-italic" style="font-size:smaller">
                        {{ $t("Created by:") }} {{ itemInfo.CreatedBy }} {{ $formatDate(itemInfo.Created, 'Short Date') }}
                    </div>
                </div>
            </template>
            <template v-else>
                {{$t('Loading...')}}
            </template>
        </div>
    </div>
</template>

<script lang="ts">

const ItemCache = new Map<number, IitemInfo>();

export interface IitemInfo {
    ID: string;
    Title: string;
    OrgUnit: string;
    CompletionPhase: string;
    HandoverType: string;
    Created: Date;
    Completed: Date;
    Closed: Date;
    CreatedBy: string;
    Description: string;
    DueDate: Date;
    Process:  string;
};

export interface IScopeItemHoverProps {
    itemId?: number;
}
</script>

<script setup lang="ts">
import { API } from 'o365-modules';
import { ref, watch, onMounted } from 'vue';

const props = defineProps<IScopeItemHoverProps>();

const showContent = ref(false);
const isLoaded = ref(false);
const itemInfo = ref<IitemInfo | null>(null);
const hoverElement = ref<HTMLElement | null>(null);

function hoverShow() {
    showContent.value = true;
    if (isLoaded.value && itemInfo.value?.ID != `${props.itemId}`) {
        handleShow();
    }
}

function hoverHide() {
    showContent.value = false;
}

defineExpose({hoverElement, hoverHide, hoverShow});

async function loadItemData(itemId: number) {
    const result = await API.request({
        requestInfo: '/nt/api/data/aviw_Scope_Items',
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            "viewName": "aviw_Scope_Items",
            "distinctRows": false,
            "skip": 0,
            "fields": [
                { name: "ID", type: "string" },
                { name: "Title", type: "string" },
                { name: "OrgUnit", type: "string" },
                { name: "CompletionPhase", type: "string" },
                { name: "HandoverType", type: "string" },
                { name: "Description", type: "string" },
                { name: "CreatedBy", type: "string" },
                { name: "Created", type: "date" },
                { name: "Deleted", type: "date" },
                { name: "Completed", type: "date" },
                { name: "Closed", type: "date" },
                { name: "DueDate", type: "date" },
                { name: "DetailApp_ID", type: "string" },
                { name: "Process", type: "string" },
                { name: "MobileDetailApp_ID", type: "string" },
            ],
            "maxRecords": 1,
            "whereClause": `ID = ${itemId}`,
            "masterDetailString": null,
            "filterString": null,
            "operation": "retrieve"
        })
    });
    return result != null ? result[0] : result;
}

async function handleShow() {
    isLoaded.value = false;
    itemInfo.value = null;
    if (props.itemId == null) { return; }
    let itemData = ItemCache.get(props.itemId);
    if (itemData == null) {
        try {
            itemData = await loadItemData(props.itemId);
            if (itemData == null) { return; }
            ItemCache.set(props.itemId, itemData);
        } catch (ex) {
            console.error(ex);
            return;
        }
    }
    itemInfo.value = itemData;
    isLoaded.value = true;
}

watch(() => props.itemId, (_newitemId) => {
    handleShow();
});

onMounted(() => {
    handleShow();
});

</script>
